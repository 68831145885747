.new-task-copy__root {
  display: flex;
  align-items: center;

  flex-direction: column;
}

.new-task-copy__root__card {
  width: 795px;
  margin: 10px;
}
.new-task-copy__root__card__content {
  margin-top: 20px;
}

.new-task-copy__root_submit-btn {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 795px;
}

.new-task-copy__root__input-width {
  width: 300px;
}
