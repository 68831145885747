.card {
  .title {
    height: 100px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
  }

  .inputGroup {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-start;

    div {
      margin-right: 10px;
    }
  }

  .list {
    margin-top: 20px;
  }
}
